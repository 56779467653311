
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Logo from "@/components/utils/Logo.vue";
import Input from "@/components/utils/Input.vue";
import Button from "@/components/utils/Button.vue";
import ButtonOutlined from "@/components/utils/ButtonOutlined.vue";
import BlockItem from "@/components/utils/BlockItem.vue";
import LoadingScreen from "@/components/global/LoadingScreen.vue";
import HintLink from "@/components/utils/HintLink.vue";
import { JoinSessionPayload } from "@/store/modules/session";
import { DeclineInvite } from "@/store/modules/session";
import DeclineOptionsJson from "@/store/data/declineOptions.json"; // @ is an alias to /src
import Select from "@/components/utils/Select.vue"; // @ is an alias to /src
import ErrorBox from "@/components/utils/ErrorBox.vue";

import InputMaxLengths from "@/store/data/inputMaxLengths.json";
import { getLocalisedError } from "@/utils/apiUtils";

@Options({
  data() {
    return {
      sessionInfo: {
        userName: "",
        name: "",
        room: "",
        notes: "",
      },
      accessCode: null,
      declined: false,
      declineMessage: "None",
      declineMessageOption: "",
      declineOptions: DeclineOptionsJson,
      declinedNotice: false,
      loaded: false,
      loadingMessage: this.$t("pages.accessSession.loading"),
      error: "",
      resendCodePending: false,
      resendCodeError: false,
      partialPhoneNumber: undefined,
      errorHeading: "",
      errorContent: "",
      errorFixed: true,
      inputMaxLengths: InputMaxLengths[0],
    };
  },
  components: {
    HeaderShadow,
    Logo,
    Input,
    Button,
    ButtonOutlined,
    BlockItem,
    HintLink,
    Select,
    LoadingScreen,
    ErrorBox,
  },
  mounted() {
    this.checkIfDecline();
    console.log(this.$route.query.decline);

    this.$store
      .dispatch("getInvitationInfo", this.$route.params.inviteId)
      .then((response: Record<string, any>) => {
        this.sessionInfo = {
          userName: response.data.userName,
          name: response.data.sessionName,
          room: response.data.sessionRoom,
          notes: response.data.sessionNotes,
        };
        this.loaded = true;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        this.loaded = true;
        this.errorHeading = "";
        this.errorContent = getLocalisedError(error);
      });
    document.title =
      this.$t("pages.accessSession.title") +
      " - " +
      this.$t("global.defaults.browserTitle");
  },
  methods: {
    checkIfDecline() {
      if (this.$route.query.decline) {
        this.declined = true;
      } else {
        this.declined = false;
      }
    },
    accessRemoteClient() {
      this.loaded = false;
      this.loadingMessage = this.$t("pages.accessSession.loadingJoining");
      this.$store
        .dispatch("joinSession", {
          username: this.sessionInfo.userName,
          inviteId: this.$route.params.inviteId,
          accessCode: this.accessCode,
        } as JoinSessionPayload)
        .then(() => {
          this.$router.push({
            name: "RemoteClientSession",
            query: { inviteId: this.$route.params.inviteId },
          });
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    declineRemoteClient() {
      this.loaded = false;
      this.loadingMessage = this.$t("pages.accessSession.loadingDeclined");
      if (this.declineMessage === "Other") {
        this.declineMessage =
          this.$t("selectOptions.declineReasons.other") +
          " - " +
          this.declineMessageOther;
      }
      console.log(this.declineMessage);

      this.$store
        .dispatch("declineSession", {
          inviteId: this.$route.params.inviteId,
          reason: this.declineMessage,
        } as DeclineInvite)
        .then(() => {
          this.loaded = true;
          this.declinedNotice = true;
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.loaded = true;
          this.errorHeading = "";
          this.errorContent = getLocalisedError(error);
        });
    },
    resendCode() {
      if (!this.resendCodePending) {
        this.resendCodePending = true;
        this.resendCodeError = false;
        this.partialPhoneNumber = undefined;
        this.$store
          .dispatch("resendAccessCode", this.$route.params.inviteId)
          .then((response: Record<string, any>) => {
            this.resendCodePending = false;
            if (response.data && response.data.partialPhoneNumber) {
              this.partialPhoneNumber = response.data.partialPhoneNumber;
            } else {
              this.resendCodeError = true;
            }
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((error: any) => {
            this.loaded = true;
            this.errorHeading = "";
            this.errorContent = getLocalisedError(error);

            this.resendCodePending = false;
            this.resendCodeError = true;
          });
      }
    },
    uppercase() {
      this.accessCode = this.accessCode.toUpperCase();
    },
    validCode() {
      return (
        this.accessCode && this.accessCode.length >= 8 && this.accessCode >= 0
      );
    },
  },
})
export default class RemoteClientAccessSession extends Vue {}
