
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    heading: String,
    value: String,
  },
})
export default class BlockItem extends Vue {}
