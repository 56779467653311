import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "bg-white dark:bg-nucleus-darkerBlue p-6"
}
const _hoisted_2 = { class: "text-nucleus-text text-2xl mb-10" }
const _hoisted_3 = { class: "font-medium text-lg" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "flex" }
const _hoisted_6 = {
  key: 0,
  class: "opacity-70"
}
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = {
  key: 1,
  class: "opacity-70"
}
const _hoisted_9 = { class: "inline text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver underline transition ml-1 cursor-pointer" }
const _hoisted_10 = {
  key: 2,
  class: "bg-white dark:bg-nucleus-darkerBlue p-6"
}
const _hoisted_11 = { class: "text-nucleus-text text-2xl mb-10" }
const _hoisted_12 = { class: "font-medium text-lg" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "flex" }
const _hoisted_15 = { class: "inline text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver underline transition ml-1 cursor-pointer" }
const _hoisted_16 = {
  key: 3,
  class: "bg-white dark:bg-nucleus-darkerBlue p-6"
}
const _hoisted_17 = { class: "text-nucleus-text text-2xl mb-10" }
const _hoisted_18 = { class: "font-medium text-lg" }
const _hoisted_19 = { class: "mb-6 text-nucleus-text" }
const _hoisted_20 = { class: "font-medium" }
const _hoisted_21 = {
  key: 4,
  class: "p-4 mt-6 bg-nucleus-error text-nucleus-white text-center"
}
const _hoisted_22 = {
  key: 0,
  class: "opacity-70"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingScreen = _resolveComponent("LoadingScreen")!
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_ErrorBox = _resolveComponent("ErrorBox")!
  const _component_BlockItem = _resolveComponent("BlockItem")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Select = _resolveComponent("Select")!
  const _component_ButtonOutlined = _resolveComponent("ButtonOutlined")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_LoadingScreen, {
      loaded: _ctx.loaded,
      message: _ctx.loadingMessage
    }, null, 8, ["loaded", "message"]),
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_Logo),
    (_ctx.errorHeading || _ctx.errorContent)
      ? (_openBlock(), _createBlock(_component_ErrorBox, {
          key: 0,
          heading: _ctx.errorHeading,
          content: _ctx.errorContent,
          fixed: _ctx.errorFixed,
          onCloseError: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.errorHeading = '';
        _ctx.errorContent = '';
      })
        }, null, 8, ["heading", "content", "fixed"]))
      : _createCommentVNode("", true),
    (!_ctx.declined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("pages.accessSession.title")), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.sessionInfo?.userName), 1),
          _createElementVNode("p", {
            class: "mb-6 text-nucleus-text",
            innerHTML: _ctx.$t('pages.accessSession.description')
          }, null, 8, _hoisted_4),
          _createVNode(_component_BlockItem, {
            heading: _ctx.$t('global.defaults.headingName'),
            value: _ctx.sessionInfo?.name
          }, null, 8, ["heading", "value"]),
          _createVNode(_component_BlockItem, {
            heading: _ctx.$t('inputs.room.label'),
            value: _ctx.sessionInfo?.room
          }, null, 8, ["heading", "value"]),
          _createVNode(_component_BlockItem, {
            heading: _ctx.$t('global.defaults.headingNotes'),
            value: _ctx.sessionInfo?.notes
          }, null, 8, ["heading", "value"]),
          _createElementVNode("form", {
            class: "mt-10",
            onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.accessRemoteClient && _ctx.accessRemoteClient(...args)), ["prevent"]))
          }, [
            _createVNode(_component_Input, {
              value: _ctx.accessCode,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accessCode) = $event)),
              "input-type": "number",
              label: _ctx.$t('inputs.accessCode.label'),
              id: "access-code",
              icon: "",
              maxLength: _ctx.inputMaxLengths.code,
              placeholder: _ctx.$t('inputs.accessCode.placeholder'),
              onKeyup: _ctx.uppercase,
              autocomplete: "off"
            }, null, 8, ["value", "label", "maxLength", "placeholder", "onKeyup"]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_Button, {
                type: "submit",
                name: _ctx.$t('buttons.join'),
                disabled: !_ctx.validCode()
              }, null, 8, ["name", "disabled"])
            ]),
            _createElementVNode("div", {
              class: "mt-6 text-nucleus-text",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.resendCode && _ctx.resendCode(...args)))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.accessSession.noCode")) + " ", 1),
              _createElementVNode("span", {
                class: _normalizeClass(["inline text-nucleus-primaryBlue hover:text-nucleus-primaryMouseOver underline transition ml-1 cursor-pointer", {
              'opacity-50 cursor-not-allowed': _ctx.resendCodePending,
            }])
              }, _toDisplayString(_ctx.$t("buttons.resendCode")), 3)
            ]),
            (_ctx.partialPhoneNumber)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$t("pages.accessSession.codeSent")) + " ", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.partialPhoneNumber), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.resendCodeError)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("pages.accessSession.unable")), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "mt-6 text-nucleus-text",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.declined = true))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.accessSession.cantAttend")) + " ", 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("pages.accessSession.declineInvite")), 1)
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (_ctx.declined && !_ctx.declinedNotice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.$t("pages.accessSession.declineInvite")), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.sessionInfo?.userName), 1),
          _createElementVNode("p", {
            class: "mb-6 text-nucleus-text",
            innerHTML: _ctx.$t('pages.accessSession.declineDescription')
          }, null, 8, _hoisted_13),
          _createVNode(_component_BlockItem, {
            heading: _ctx.$t('global.defaults.headingName'),
            value: _ctx.sessionInfo?.name
          }, null, 8, ["heading", "value"]),
          _createVNode(_component_BlockItem, {
            heading: _ctx.$t('inputs.room.label'),
            value: _ctx.sessionInfo?.room
          }, null, 8, ["heading", "value"]),
          _createVNode(_component_BlockItem, {
            heading: _ctx.$t('global.defaults.headingNotes'),
            value: _ctx.sessionInfo?.notes ? _ctx.sessionInfo.notes : 'None'
          }, null, 8, ["heading", "value"]),
          _createElementVNode("form", {
            class: "mt-10",
            onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.declineRemoteClient && _ctx.declineRemoteClient(...args)), ["prevent"]))
          }, [
            _createVNode(_component_Select, {
              value: _ctx.declineMessage,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.declineMessage) = $event)),
              id: "message",
              label: _ctx.$t('inputs.declineSelect.label'),
              options: _ctx.declineOptions
            }, null, 8, ["value", "label", "options"]),
            (_ctx.declineMessage === 'Other')
              ? (_openBlock(), _createBlock(_component_Input, {
                  key: 0,
                  value: _ctx.declineMessageOther,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.declineMessageOther) = $event)),
                  "input-type": "text",
                  label: _ctx.$t('inputs.declineMessage.label'),
                  id: "other",
                  icon: "",
                  maxLength: _ctx.inputMaxLengths.notes,
                  placeholder: _ctx.$t('inputs.declineMessage.placeholder')
                }, null, 8, ["value", "label", "maxLength", "placeholder"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_ButtonOutlined, {
                type: "submit",
                name: _ctx.$t('pages.accessSession.declineInvite'),
                error: true
              }, null, 8, ["name"])
            ]),
            _createElementVNode("div", {
              class: "mt-6 text-nucleus-text",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.declined = false))
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t("pages.accessSession.nowJoin")) + " ", 1),
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("pages.accessSession.joinInstead")), 1)
            ])
          ], 32)
        ]))
      : _createCommentVNode("", true),
    (_ctx.declined && _ctx.declinedNotice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("h1", _hoisted_17, _toDisplayString(_ctx.$t("pages.accessSession.sessionDeclined")), 1),
          _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$t("pages.accessSession.declinedSession")), 1),
          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t("pages.accessSession.joinLater")), 1),
          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t("pages.accessSession.closeTab")), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createTextVNode(_toDisplayString(_ctx.error) + " ", 1),
          (_ctx.error === 'Unable to join session')
            ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t("pages.accessSession.incorrect")), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}