import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8019b2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-white dark:bg-nucleus-darkerBlue p-6 text-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-nucleus-text text-2xl mb-4"
}
const _hoisted_3 = {
  key: 1,
  class: "text-nucleus-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderShadow = _resolveComponent("HeaderShadow")!
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeaderShadow),
    _createVNode(_component_Logo, { class: "custom" }),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.heading)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.heading), 1))
        : _createCommentVNode("", true),
      (_ctx.content)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.content), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}