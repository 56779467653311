
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    name: String,
    type: String,
    error: Boolean,
  },
})
export default class ButtonOutlined extends Vue {}
