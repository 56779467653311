
import { Options, Vue } from "vue-class-component";
import HeaderShadow from "@/components/utils/HeaderShadow.vue";
import Logo from "@/components/utils/Logo.vue";

enum DisconnectReason {
  SessionEnded = "SESSION_ENDED",
  InviteRevoked = "INVITE_REVOKED",
  UserRejoined = "USER_REJOINED",
  UserLeft = "USER_LEFT",
  JoinError = "JOIN_ERROR",
}

@Options({
  data() {
    return {
      heading: "",
      content: "",
    };
  },
  components: {
    HeaderShadow,
    Logo,
  },
  mounted() {
    this.checkWhyLeft(this.$route.query.reason);
    this.$store.dispatch("removeActiveSession");
  },
  methods: {
    checkWhyLeft(reason: string) {
      switch (reason) {
        case DisconnectReason.SessionEnded:
          this.heading = this.$t("pages.sessionEnded.title");
          this.content = this.$t("pages.sessionEnded.close");
          break;

        case DisconnectReason.UserRejoined:
          this.heading = this.$t("pages.sessionEnded.connectOne");
          this.content = "";
          break;

        case DisconnectReason.InviteRevoked:
          this.heading = this.$t("pages.sessionEnded.removed");
          this.content = "";
          break;

        case DisconnectReason.UserLeft:
          this.heading = this.$t("pages.sessionEnded.left");
          this.content = this.$t("pages.sessionEnded.rejoin");
          break;

        case DisconnectReason.JoinError:
          this.heading = this.$t("pages.sessionEnded.error");
          this.content = this.$t("pages.sessionEnded.tryAgain");
          break;

        default:
          this.heading = this.$t("pages.sessionEnded.disconnected");
          this.content = this.$t("pages.sessionEnded.rejoinHint");
          break;
      }
    },
  },
})
export default class RemoteClientEnded extends Vue {}
